import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <div className={'notification-container'}>
      <h2>
        The Non-Vehicle Assets online payment portal is being retired. To make a payment or inquiry on your account, please contact your CBDM or send an email to:&nbsp;<a href={'mailto:fpfschrg@ford.com'}>fpfschrg@ford.com</a>.&nbsp;Thank you!
      </h2>
    </div>
  </React.StrictMode>
);

